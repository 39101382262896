<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CRow>
          <CCol sm="12">
            <CCard>
              <CCardHeader>
                <CCardTitle>Details of Project #{{ project.name }}</CCardTitle>
                <div class="card-header-actions"></div>
              </CCardHeader>

              <CCardBody>
                <CRow>
                  <CCol sm="6">
                    <CInput
                      name="name"
                      label="Name"
                      placeholder="Enter the numbers or name of the project"
                      v-model="project.name"
                      required
                    />
                  </CCol>
                  <CCol sm="6">
                    <CInput
                      name="location"
                      label="Location"
                      placeholder="Enter the location of the project"
                      v-model="project.location"
                      required
                    />
                  </CCol>
                  <CCol sm="3" class="logo-img">
                    <CImg
                      v-if="project.logo"
                      :src="storageURL + project.logo"
                      width="200"
                    />
                    <button
                      class="delete-img-btn"
                      @click="deleteLogo()"
                      v-if="project.logo"
                    >
                      <CIcon size="custom" name="cil-trash" />
                    </button>
                    <p v-if="!project.logo">No logo uploaded</p>
                  </CCol>
                  <CCol sm="6">
                    <CInputFile
                      name="logo"
                      label="Logo"
                      description="Maximum total upload size is 1MB.
                      Images can be compressed using imagecompressor.com"
                      v-on:change="setLogo"
                      required
                    />
                  </CCol>

                  <CCol sm="12" class="mt-4">
                    <ckeditor
                      :editor="editor"
                      v-model="project.description"
                      :config="editorConfig"
                    ></ckeditor>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>

          <CCol sm="12" class="project-images">
            <CCard>
              <CCardHeader>
                <CCardSubtitle>Project Images</CCardSubtitle>
              </CCardHeader>

              <CCardBody>
                <CRow>
                  <CCol sm="6">
                    <CInputFile
                      name="newImages"
                      label="Upload Images"
                      description="Maximum total upload size is 1MB.
                      Images can be compressed using imagecompressor.com"
                      v-on:change="setImages"
                      multiple
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol
                    v-for="(image, index) in project.images"
                    :key="image.id"
                    sm="3"
                  >
                    <CImg
                      :src="storageURL + image.file_name"
                      block
                      class="mb-2"
                    />
                    <button class="delete-img-btn" @click="deleteImage(index)">
                      <CIcon size="custom" name="cil-trash" />
                    </button>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>

          <CCol sm="12">
            <CButton
              variant="outline"
              type="submit"
              color="primary"
              @click="updateProject"
            >
              <CIcon name="cil-check-circle" />
              Update
            </CButton>
            <CButton
              class="float-right"
              variant="outline"
              type="submit"
              color="danger"
              @click="deleteProject"
            >
              <CIcon name="cil-trash" /> Delete
            </CButton>
          </CCol>
        </CRow>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import ProjectAPI from "/app/src/api/project.js";
import LocationAPI from "/app/src/api/location.js";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "Detail",
  data: () => {
    return {
      project: {},
      newLogo: {},
      newImages: {},
      deleteImages: [],
      locations: [],
      storageURL: process.env.VUE_APP_STORAGE_URL,
      projectAPI: new ProjectAPI(),
      locationAPI: new LocationAPI(),
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "bold",
          "italic",
          "|",
          "link",
          "blockquote",
          "inserttable",
          "|",
          "bulletedlist",
          "numberedlist",
          "|",
          "undo",
          "redo",
        ],
      },
    };
  },
  created: function () {
    console.log(process.env.VUE_APP_STORAGE_URL);
    this.getProject(this.$route.params.id);
  },
  methods: {
    getProject: function (id) {
      this.$store.dispatch("loading");
      let self = this;
      self.projectAPI
        .detail(id)
        .then((project) => {
          this.$store.dispatch("stopLoading");
          self.project = project;
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading");
          console.log(error);
          this.$alert.show({ type: "danger", message: error });
        });
    },
    validateSubmission: function () {
      if (this.project.name.length < 1) return "Name is required";
      if (this.project.location.length < 1) return "Location is required";
      for (let i = 0; i < this.newImages.length; i++) {
        if (this.newImages[i] && this.newImages[i].size > 1200000)
          return "Images are too large. Max. 1MB per image";
      }
      return true;
    },
    updateProject: function (id) {
      console.log(this.project);

      let message = this.validateSubmission();
      if (message !== true) {
        this.$alert.show({ type: "danger", message: message });
        return;
      }

      this.$store.dispatch("loading");
      let self = this;
      let data = {
        id: this.project.id,
        name: this.project.name,
        location: this.project.location,
        logo: this.project.logo,
        description: this.project.description,
        deleteImages: this.deleteImages,
      };
      this.projectAPI
        .update(data, this.newLogo, this.newImages)
        .then((project) => {
          self.$store.dispatch("stopLoading");
          self.$alert.show({
            type: "success",
            message: "Project has been updated",
          });
          self.project = project;
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("stopLoading");
          this.$alert.show({ type: "danger", message: error });
        });
    },
    deleteProject: function (id) {
      const params = {
        title: "Confirm",
        text: "Are you sure you want to delete?",
        onConfirm: () => {
          this.$store.dispatch("loading");
          this.projectAPI
            .delete(this.$route.params.id)
            .then((result) => {
              this.$store.dispatch("stopLoading");
              console.log(result);
              this.$router.push({ path: "/manager/projects" });
            })
            .catch((error) => {
              this.$store.dispatch("stopLoading");
              console.log(error);
              this.$alert.show({ type: "danger", message: error });
            });
        },
      };
      this.$confirm.show(params);
    },
    setLogo: function (files, e) {
      this.newLogo = files[0];
      console.log(this.newLogo);
    },
    deleteLogo: function () {
      console.log("delete logo");
      this.project.logo = "";
    },
    setImages: function (files, e) {
      this.newImages = files;
      console.log(this.newImages);
    },
    deleteImage: function (index) {
      this.deleteImages.push(this.project.images[index].id);
      this.$delete(this.project.images, index);
      console.log(this.deleteImages);
    },
  },
};
</script>

<style scoped>
.logo-img img {
  width: 100%;
}
.project-images img {
  width: 100%;
}

.delete-img-btn {
  position: absolute;
  top: -10px;
  right: 5px;
  z-index: 999;
  color: white;
  background-color: red;
  padding: 5px;
  border-radius: 50px;
  width: 25px;
  height: 25px;
  border: none;
}

.delete-img-btn svg {
  margin-top: -9px;
}
</style>
